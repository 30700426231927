/*
 * @Autor: Hongting Yuan
 * @Date: 2021-12-07 08:34:08
 * @LastEditors: Hongting Yuan
 * @LastEditTime: 2022-08-16 16:55:30
 * @Description: file content
 * @FilePath: \video_test\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import crypto from '../crypto/crypto'

Vue.use(VueRouter)

var mpath = []
for (var i = 1; i < 10; i++) {
  mpath[i] = crypto.set(i)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/' + mpath[2],
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue'),
    children: [
      { path: '/' + mpath[2], name: 'Video', component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue') },
      { path: '/' + mpath[4], name: 'Test02', component: () => import(/* webpackChunkName: "about" */ '../views/Test02.vue') }
    ]
  }
]
const router = new VueRouter({
  routes
})

export default router
