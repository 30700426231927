/*
 * @Autor: Hongting Yuan
 * @Date: 2022-08-16 14:44:41
 * @LastEditors: Hongting Yuan
 * @LastEditTime: 2022-08-16 17:52:25
 * @Description: file content
 * @FilePath: \video_test\src\main.js
 */
import Vue from 'vue'
import App from './App'
// 引入element-ui
import ElementUI from 'element-ui'

// 引入路由配置（如果安装时有选择安装路由，这里默认已经配置好)
import router from './router'
import axios from 'axios'
import VConsole from 'vconsole'
new VConsole()
Vue.prototype.axios = axios
axios.defaults.baseURL = '/api'


Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
