/*
 * @Autor: Ruohong Yu
 * @Date: 2021-11-27 16:40:05
 * @LastEditors: Hongting Yuan
 * @LastEditTime: 2022-08-16 15:00:26
 * @Description: 加密
 * @FilePath: \video_test\src\crypto\crypto.js
 */

import CryptoJS from 'crypto-js'
export default { // 加密
  /**
   * @description: 加密
   * @param {*} word
   * @param {*} keyStr
   * @return {*}
   * @author: Ruohong Yu
   */
  set(word, keyStr) {
    keyStr = keyStr || 'abcdef0123456789' // 16位的密钥，自己定义，和下面的密钥要相同
    var key = CryptoJS.enc.Utf8.parse(keyStr)
    var srcs = CryptoJS.enc.Utf8.parse(word)
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
  },

  /**
   * @description: 解密
   * @param {*} word
   * @param {*} keyStr
   * @return {*}
   * @author: Ruohong Yu
   */
  get(word, keyStr) {
    keyStr = keyStr || 'abcdef0123456789'
    var key = CryptoJS.enc.Utf8.parse(keyStr)
    var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
}
